<template>
  <PageContainer
    :title="$translations.promos['packages'].generic['available-specialists']"
    :back="actions.back"
  >
    <template v-slot:content>
      <div class="segment" v-if="loading">
        <div class="segment-content">
          <SkeletonDefault />
        </div>
      </div>

      <div v-if="!loading" class="segment">
        <div class="segment-content">
          <div class="heading-small padding-bottom">
            {{ specialistResult }}
          </div>
          <SpecialistCard
            v-bind:key="specialist.id"
            v-for="specialist in specialists"
            v-bind:specialist="specialist"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, Promos } from '@seliaco/red-panda'
import SkeletonDefault from '@/components/skeletons/SkeletonDefault'
import SpecialistCard from '@/components/cards/specialist-card/SpecialistCardWrapper.vue'

export default {
  name: 'AvailableSpecialists',
  components: {
    SpecialistCard,
    PageContainer,
    SkeletonDefault
  },
  props: {
    packageId: String,
    preserveBackRoute: Boolean
  },
  data () {
    return {
      loading: false,
      specialists: [],
      actions: {
        back: {
          callback: () => {
            if (this.preserveBackRoute) {
              this.$router.go(-1)
            }
            if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.push({ name: 'Home' })
            }
          }
        }
      }
    }
  },
  mounted () {
    this.getSpecialists()
  },
  computed: {
    specialistResult () {
      return `${this.specialists?.length} ${this.$translations.promos.packages.generic.specialist}`
    }
  },
  methods: {
    getSpecialists () {
      this.loading = true

      Promos.Packages.listSpecialists(this.packageId)
        .then((response) => {
          this.specialists = response || []
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
